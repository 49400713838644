import qwest from "qwest";


class SignIn {

    /**
     * Constructor.
     *
     * @constructor
     */
    constructor() {

        this.container = document.getElementById("container")
    }

    /**
     * Toggles "flip" class which leads to flip effect.
     */
    flip() {

        this.container.classList.toggle("flip")
    }

    /**
     * Tells to server check user's credentials and if they are
     * correct send PIN via SMS.
     */
    sendCode(e) {

        var email = document.getElementById("email").value
        var password = document.getElementById("password").value
        var pbx_account = document.getElementById("id_pbx_account").value
        var _this = this
        var emailLoader = document.querySelector(".front #email-btn .loader")
        var emailNormal = document.querySelector(".front #email-btn .normal")
        var smsLoader = document.querySelector(".front #sms-btn .loader")
        var smsNormal = document.querySelector(".front #sms-btn .normal")
        var pressedButton = e.target.getAttribute("name")

        // Show loading indicator.
        if (pressedButton == "sms") {
            smsLoader.classList.remove("hide")
            smsNormal.classList.add("hide")
            this._hideErrors()
        } else {
            emailLoader.classList.remove("hide")
            emailNormal.classList.add("hide")
            this._hideErrors()
        }

        qwest.post("/core/sign-in/send-code/", {username: email, password: password, pbx_account: pbx_account, buttonType: pressedButton}).then((xhr, response) => {

            // Hide loading indicator.
            if (pressedButton == "sms") {
                smsLoader.classList.add("hide")
                smsNormal.classList.remove("hide")
            } else {
                emailLoader.classList.add("hide")
                emailNormal.classList.remove("hide")
            }

            if (response.status) {
                if (response.deny_access) {
                    _this._showError("ip-error")
                } else if (response.required_pin) {
                    _this.flip()

                    var code = document.getElementById("code")
                    code.value = ""
                    code.focus()
                } else {
                    document.getElementById("code").style.display = "none"
                    document.getElementById("code-not-required").classList.remove("hide")
                    _this.flip()

                    setTimeout(function() {
                        _this.signIn()
                    }, 3000)
                }
            }

            else
                _this._showError("credentials-error")
        });
    }

    /**
     * Validates user's credentials and PIN code. If they are
     * correct submits form that leads to user authentication.
     */
    signIn() {

        var email = document.getElementById("email").value
        var password = document.getElementById("password").value
        var code = document.getElementById("code").value
        var _this = this
        var loader = document.querySelector(".back button .loader")
        var normal = document.querySelector(".back button .normal")

        this._hideErrors()

        qwest.post("/core/sign-in/validate/", {username: email, password: password, code: code}).then((xhr, response) => {

            if (response.status) {

                // Show loading indicator.
                loader.classList.remove("hide")
                normal.classList.add("hide")

                document.querySelector("form").submit()
            }

            else
                _this._showError("pin-error")
        });
    }

    /**
     * Shows box with error messages.
     *
     * @param {Sting} id ID of DOM element with the error.
     */
    _showError(id) {

        this._hideErrors()
        document.getElementById(id).classList.remove("hide")
    }

    /**
     * Hides PIN and credentials error boxes.
     */
    _hideErrors() {

        document.getElementById("pin-error").classList.add("hide")
        document.getElementById("credentials-error").classList.add("hide")
        document.getElementById("ip-error").classList.add("hide")

    }
}


export default SignIn;
